// extracted by mini-css-extract-plugin
export var button = "funcionalidade-module--button--36e5d";
export var container = "funcionalidade-module--container--9bfde";
export var desc = "funcionalidade-module--desc--8bc9c";
export var funcionalidadeImg = "funcionalidade-module--funcionalidade-img--c5053";
export var funcionalidadeText = "funcionalidade-module--funcionalidade-text--4590c";
export var imageFitCenter = "funcionalidade-module--image-fit-center--bfbdd";
export var img = "funcionalidade-module--img--a6373";
export var left = "funcionalidade-module--left--36417";
export var line = "funcionalidade-module--line--57506";
export var mbBig = "funcionalidade-module--mb-big--9035c";
export var statement = "funcionalidade-module--statement--44ac9";
export var subtitle = "funcionalidade-module--subtitle--8f522";
export var title = "funcionalidade-module--title--be42c";