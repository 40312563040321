export function isBetweenDateRange(): boolean {
  const today = new Date();
  const startDate = new Date('2024-11-01');
  const endDate = new Date('2024-11-30');

  today.setHours(0, 0, 0, 0);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  return today >= startDate && today <= endDate;
}
