import { Duvida as DuvidaInterface } from '@/constants/duvidas';
import React from 'react';
import * as styles from './duvida.module.scss';

export interface DuvidaProps {
  duvida: DuvidaInterface;
}

const Duvida = ({ duvida }: DuvidaProps) => {
  return (
    <div className={styles.container}>
      <img src={duvida.image} alt={duvida.title} loading="lazy" />
      <div>
        <h3>{duvida.title}</h3>
        <p className={styles.text}>{duvida.text}</p>
      </div>
    </div>
  );
};

export default Duvida;
