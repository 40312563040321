import React from 'react';
import { Container } from 'react-bootstrap';
import Button from '@/components/Button';
import { TypeWrite } from '@/components';
import { buttonEvents, homeButtonNames } from '@/constants/analytics';
import { trackButtonClick } from '@/services/analytics';

import softwareOdontologico from '@/assets/images/software-odontologico.svg';
import heroBlackNovember from '@/assets/images/hero-black-november.png';
import heroMobileBlackNovember1 from '@/assets/images/hero-mobile-black-november-1.png';
import heroMobileBlackNovember2 from '@/assets/images/hero-mobile-black-november-2.png';
import heroMobileBlackNovember3 from '@/assets/images/hero-mobile-black-november-3.png';
import Verified from '@/assets/svg/verified.svg';
import * as styles from './hero.module.scss';
import Image from '@/components/Image';
import { Link, navigate } from 'gatsby';
import { routes } from '@/constants/routes';
import clsx from 'clsx';

export interface HeroProps {
  blackNovember?: boolean;
}

const Hero = ({ blackNovember }: HeroProps) => {
  const strings = ['dentistas.', 'secretárias.', 'clínicas.', 'consultórios.'];

  const linkEducate =
    'https://ajuda.simplesdental.com/pt-BR/articles/6387374-como-realizar-o-reembolso-da-mensalidade';

  const handleTestarGratis = () => {
    navigate(routes.cadastro);
    trackButtonClick({
      event: buttonEvents.home,
      buttonName: homeButtonNames.testarGratisHero,
    });
  };

  const handleFalarEspecialista = () => {
    navigate(routes.cadastroEspecialista);
    trackButtonClick({
      event: buttonEvents.home,
      buttonName: homeButtonNames.falarEspecialistaHero,
    });
  };

  return (
    <>
      <Container>
        <div className={clsx(styles.container, { [styles.blackNovember]: blackNovember })}>
          {blackNovember && (
            <>
              <Image
                src={heroMobileBlackNovember1}
                alt="10 meses com 35% OFF"
                title="10 meses com 35% OFF"
                className={styles.imgBlackNovemberMobile}
              />
              <Image
                src={heroMobileBlackNovember3}
                alt="10 meses com 35% OFF"
                title="10 meses com 35% OFF"
                className={styles.imgBlackNovemberMobileAlt}
              />
              <div className={clsx(styles.heroActions, styles.heroActionsAlt)}>
                <div className={styles.heroButtons}>
                  <Button
                    text="Testar grátis 7 dias"
                    variant="orange"
                    onClick={handleTestarGratis}
                  />
                  <Button
                    text="Falar com especialista"
                    variant="green"
                    onClick={handleFalarEspecialista}
                  />
                </div>
              </div>
            </>
          )}
          <div className={styles.heroText}>
            <h1 className={styles.title}>
              O melhor software odontológico para{' '}
              <TypeWrite className={styles.typeWrite} strings={strings} />
            </h1>
            <h2 className={styles.subtitle}>
              O Simples Dental tem todas as ferramentas que você precisa para sua clínica crescer.
            </h2>
            <div className={styles.heroActions}>
              <div className={styles.heroButtons}>
                <Button text="Testar grátis 7 dias" variant="orange" onClick={handleTestarGratis} />
                <Button
                  text="Falar com especialista"
                  variant="green"
                  onClick={handleFalarEspecialista}
                />
              </div>
            </div>

            <hr />

            <div className={styles.desc}>
              <Verified className={styles.descIcon} />
              <div className={styles.descText}>
                <h3>
                  Satisfação garantida ou seu dinheiro de volta,{' '}
                  <a href={linkEducate} target="_blank" rel="noreferrer">
                    saiba mais
                  </a>
                </h3>

                <hr />

                <p>
                  <span>Mais de 79 mil profissionais</span> de odontologia usam o Simples Dental
                  todos os dias!
                </p>
              </div>
            </div>
          </div>
          {blackNovember ? (
            <>
              <Link to="/planos-e-precos#regras">
                <Image
                  src={heroMobileBlackNovember2}
                  alt="Ganhe uma Alexa"
                  title="Ganhe uma Alexa"
                  className={styles.imgBlackNovemberMobile}
                />
                <Image
                  src={heroBlackNovember}
                  alt="10 meses com 35% OFF"
                  title="10 meses com 35% OFF"
                  className={styles.imgBlackNovember}
                />
              </Link>
            </>
          ) : (
            <Image
              type="svg"
              src={softwareOdontologico}
              alt="Software odontológico"
              title="Imagem do Software odontológico da Simples Dental"
              className={styles.imgDesktop}
              width={866}
              height={535}
            />
          )}
        </div>
      </Container>
      <div className={styles.after}></div>
    </>
  );
};

export default Hero;
