import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '@/components/Button';

import Duvida from './Duvida/Duvida';
import DUVIDAS from '@/constants/duvidas';
import * as styles from './duvidas.module.scss';
import { trackButtonClick } from '@/services/analytics';
import { buttonEvents, homeButtonNames } from '@/constants/analytics';
import { navigate } from 'gatsby';
import { routes } from '@/constants/routes';

const Duvidas = () => {
  const handleClick = () => {
    navigate(routes.cadastro);
    trackButtonClick({
      event: buttonEvents.home,
      buttonName: homeButtonNames.testarGratisDuvidas,
    });
  };

  return (
    <div className={styles.container}>
      <Container>
        <p className="subtitle text-center scorpion-grass-blue">Entenda melhor</p>
        <h2 className="title bahama-blue text-center">
          O que você precisa saber sobre o Simples Dental
        </h2>
        <Row>
          {DUVIDAS.map(d => (
            <Col xs={12} lg={6} key={d.id}>
              <Duvida duvida={d} />
            </Col>
          ))}
        </Row>

        <Button text="Testar por 7 dias grátis" variant="orange" onClick={() => handleClick()} />
      </Container>
    </div>
  );
};

export default Duvidas;
