import React from 'react';
import Funcionalidade from '../Funcionalidade/Funcionalidade';
import { funcionalidade, novidadesItems } from './constants';

import * as styles from './novidades.module.scss';

const Novidades = () => {
  return (
    <Funcionalidade funcionalidade={funcionalidade}>
      <div className={styles.container}>
        {novidadesItems.map(n => (
          <div className={styles.novidadeItem} key={n.id}>
            <span>
              <strong>
                {n.name}
                <span>: </span>
              </strong>
              <p> {n.desc}</p>
            </span>
          </div>
        ))}
      </div>
    </Funcionalidade>
  );
};

export default Novidades;
