import seguranca from '@/assets/images/seguranca.svg';
import ilimitado from '@/assets/images/ilimitado.svg';
import exportar from '@/assets/images/exportar.svg';
import smile from '@/assets/images/smile.svg';
import espacoIlimitado from '@/assets/images/espaco-ilimitado.svg';
import melhoriasContinuas from '@/assets/images/melhorias-continuas.svg';

export interface Duvida {
  id: number;
  image: string;
  title: string;
  text: string;
}

const DUVIDAS: Duvida[] = [
  {
    id: 1,
    image: seguranca,
    title: 'Seguro como o seu banco',
    text: 'A segurança dos seus dados é a coisa mais importante para nós. Toda informação inserida no Simples Dental é armazenada em nossos servidores de forma segura e monitorada.',
  },
  {
    id: 2,
    image: smile,
    title: 'Atendimento em tempo real',
    text: 'Converse com pessoas de verdade, tire qualquer dúvida em tempo real. Tudo isso é feito diretamente no chat do software ou no WhatsApp.',
  },
  {
    id: 3,
    image: ilimitado,
    title: 'Cadastro ilimitado de profissionais',
    text: 'Cadastre quantos profissionais quiser, sem pagar nada a mais por isso.',
  },
  {
    id: 4,
    image: exportar,
    title: 'Importação de dados',
    text: 'Importe o cadastro dos seus pacientes de outros softwares e organize tudo no Simples Dental.',
  },
  {
    id: 5,
    image: espacoIlimitado,
    title: 'Espaço ilimitado para imagens',
    text: 'Salve imagens diretamente do celular no Aplicativo Simples Dental! Assim você elimina as pastas e fotos de pacientes do seu dispositivo e ganha mais espaço.',
  },
  {
    id: 6,
    image: melhoriasContinuas,
    title: 'Melhorias contínuas',
    text: 'Realizamos pesquisas e coletamos sugestões de forma contínua para melhorar sua experiência com o Simples Dental.',
  },
];

export default DUVIDAS;
