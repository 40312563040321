import React from 'react';
import ImagesProvider from '@/contexts/images';
import { Seo } from '@/components';
import { Root } from '@/layouts';
import { Funcionalidade, Hero, Numbers, Duvidas, Aplicativo, Novidades } from '@/layouts/Home';
import funcionalidades from '@/layouts/Home/Funcionalidade/constants';
import { isBetweenDateRange } from '@/services/black-november';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
    },
  ],
};

const Home = () => {
  const isBlackNovember = isBetweenDateRange();

  return (
    <ImagesProvider>
      <Seo
        description="O melhor software odontológico de toda a América Latina. Dentista, junte-se ao Simples Dental e mais de 79 mil usuários."
        schema={schema}
      />
      <Root
        variant="dark"
        blackNovember={isBlackNovember ? 'home' : undefined}
        hero={<Hero blackNovember={isBlackNovember} />}
      >
        <Novidades />
        {funcionalidades.map(f => (
          <Funcionalidade funcionalidade={f} key={f.id} />
        ))}
        <Numbers />
        <Duvidas />
        <Aplicativo />
      </Root>
    </ImagesProvider>
  );
};

export default Home;
