import gestao from '@/assets/images/menu-financeiro-simples-dental.svg';
import atendimento from '@/assets/images/tela-do-prontuario-digital-do-simples-dental.svg';
import agendamento from '@/assets/images/agenda-online-do-simples-dental.svg';
import FUNCIONALIDADES from '@/constants/funcionalidades';
import { homeButtonNames } from '@/constants/analytics';
import { ImageLoadingType } from '@/components/Image';

export interface Funcionalidade {
  id: number;
  title: string;
  subtitle: string;
  image: string;
  alt: string;
  left: boolean;
  link: string;
  gaMetadata: {
    saibaMaisButtonName: string;
  };
  statement?: {
    image: string;
    name: string;
    quote: string;
    imageWidth?: number;
    imageHeight?: number;
  };
  imageTitle?: string;
  desc?: string;
  imageFitCenter?: boolean;
  imageWidth?: number;
  imageHeight?: number;
  buttonText?: string;
  imageSmall?: string;
  imageLoading?: ImageLoadingType;
}

const funcionalidades: Funcionalidade[] = [
  {
    title: FUNCIONALIDADES.gestaoFinancas.name,
    subtitle: 'ganhe mais tempo',
    desc: 'Tome as melhores decisões: controle seus indicadores e seu fluxo de caixa diariamente. Ganhe tempo com a automação de boletos, comissionamento para dentistas e integração com a maquininha de cartão.',
    image: gestao,
    alt: 'Software para controle financeiro e de gestão',
    imageTitle: 'Software para controle financeiro e de gestão da Simples Dental',
    id: 1,
    statement: {
      image: 'bruno-ecco',
      name: 'Bruno Ecco - Dentista Administrador ',
      quote:
        'Aposentei minhas planilhas e hoje tenho controle total sobre entradas e saídas financeiras.',
    },
    left: false,
    link: FUNCIONALIDADES.gestaoFinancas.route,
    gaMetadata: {
      saibaMaisButtonName: homeButtonNames.saibaMaisGestaoFinancas,
    },
  },
  {
    title: FUNCIONALIDADES.atendimentoClinico.name,
    subtitle: 'não perca tempo',
    desc: 'Adeus à papelada! Acesse rapidamente o histórico de seus pacientes como orçamentos, imagens, receituários e muito mais. Agilidade e segurança.',
    image: atendimento,
    alt: 'Simples Dental, seu prontuário digital',
    imageTitle: 'Simples Dental, seu prontuário digital',
    id: 2,
    statement: {
      image: 'stanley-lira',
      name: 'Stanley Lira - Diretor administrativo',
      quote:
        'O prontuário digital permitiu eliminar todas as fichas de papel, que ocupavam muito espaço na clínica!',
    },
    left: true,
    link: FUNCIONALIDADES.atendimentoClinico.route,
    gaMetadata: {
      saibaMaisButtonName: homeButtonNames.saibaMaisProntuarioDigital,
    },
  },
  {
    title: FUNCIONALIDADES.agendamento.name,
    subtitle: 'tudo no seu bolso',
    desc: 'Acesse sua agenda em qualquer lugar e a qualquer hora. Tudo isso de forma simples, rápida e sem papel, integrada com WhatsApp e SMS.',
    image: agendamento,
    alt: 'Agenda online Simples dental',
    imageTitle: 'Agenda online Simples dental',
    id: 3,
    statement: {
      image: 'kalise-oliveira',
      name: 'Kalise Tainá de Oliveira - Secretária',
      quote:
        'A agenda online facilita e deixa muito mais prático a marcação de consultas e alertas de retorno.',
    },
    left: false,
    link: FUNCIONALIDADES.agendamento.route,
    gaMetadata: {
      saibaMaisButtonName: homeButtonNames.saibaMaisAgendaOnline,
    },
  },
];

export default funcionalidades;
