import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { navigate } from 'gatsby';
import clsx from 'clsx';

import { Image, Button } from '@/components';
import { trackButtonClick } from '@/services/analytics';
import { buttonEvents } from '@/constants/analytics';
import * as styles from './funcionalidade.module.scss';
import { Funcionalidade as FuncionalidadeInterface } from './constants';

export interface FuncionalidadeProps {
  funcionalidade: FuncionalidadeInterface;
  children?: ReactNode;
}

const Funcionalidade = ({ funcionalidade, children }: FuncionalidadeProps) => {
  const classes = clsx(styles.container, {
    [styles.left]: funcionalidade.left,
    [styles.mbBig]: funcionalidade.id < 4,
    [styles.imageFitCenter]: funcionalidade.imageFitCenter,
  });

  const handleClick = ({ link, gaMetadata: { saibaMaisButtonName } }: FuncionalidadeInterface) => {
    navigate(link);
    trackButtonClick({
      event: buttonEvents.home,
      buttonName: saibaMaisButtonName,
    });
  };

  return (
    <Container>
      <div className={classes}>
        <div className={styles.funcionalidadeImg}>
          <Image
            type="svg"
            src={funcionalidade.image}
            alt={funcionalidade.alt}
            title={funcionalidade.imageTitle}
            width={funcionalidade?.imageWidth ? funcionalidade?.imageWidth : 807}
            height={funcionalidade?.imageHeight ? funcionalidade?.imageHeight : 474}
            imageSmall={funcionalidade.imageSmall}
            loading={funcionalidade.imageLoading}
          />
        </div>

        <div className={styles.funcionalidadeText}>
          <p className={styles.subtitle}>{funcionalidade.subtitle}</p>
          <h2 className={styles.title}>{funcionalidade.title}</h2>
          {funcionalidade.desc && <p className={styles.desc}>{funcionalidade.desc}</p>}
          {children}
          <Button
            text={funcionalidade.buttonText || 'Saiba mais'}
            variant="primary"
            onClick={() => handleClick(funcionalidade)}
            className={styles.button}
          />
          {funcionalidade.statement && (
            <div className={styles.statement}>
              <Image
                type="png"
                src={funcionalidade.statement.image}
                alt={funcionalidade.statement.name}
                className={styles.img}
                width={
                  funcionalidade.statement.imageWidth ? funcionalidade.statement.imageWidth : 62
                }
                height={
                  funcionalidade.statement.imageHeight ? funcionalidade.statement.imageHeight : 62
                }
              />
              <div>
                <p>"{funcionalidade.statement.quote}"</p>
                <span>{funcionalidade.statement.name}</span>
              </div>
            </div>
          )}
        </div>

        {funcionalidade.id < 4 && <div className={styles.line}></div>}
      </div>
    </Container>
  );
};

export default Funcionalidade;
