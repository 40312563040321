import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StoreButton } from '@/components';
import * as styles from './aplicativo.module.scss';

import { links } from '@/constants/links';
import agenda from '@/assets/images/app-mobile.svg';
import Imagem from '@/components/Image';

const Aplicativo = () => {
  const handleClick = (link: string) => window.open(link);

  return (
    <div className={styles.container}>
      <Container>
        <Row className={styles.aplicativo}>
          <Col className={styles.containerImg}>
            <Imagem
              className={styles.agendaImg}
              src={agenda}
              alt="aplicativo simples dental"
              width={653}
              height={575}
            />
          </Col>
          <Col className={styles.infos}>
            <p className={styles.allInBag}>TUDO NO SEU BOLSO</p>
            <h2 className={styles.title}>Aplicativo Simples Dental</h2>
            <p className={styles.subTitle}>
              O aplicativo mais bem avaliado do mercado odontológico com nota 4,9 de 5 nas stores.
            </p>
            <p className={styles.subTitle}>
              Consultas, compromissos, prontuários e fotos dos seus pacientes sempre com você.
            </p>

            <Row className={styles.actions}>
              <StoreButton
                type="googleplay"
                variant="light"
                onClick={() => handleClick(links.googlePlay)}
              />
              <StoreButton
                type="appstore"
                variant="light"
                onClick={() => handleClick(links.appStore)}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Aplicativo;
