// extracted by mini-css-extract-plugin
export var container = "numbers-module--container--c8550";
export var highlight = "numbers-module--highlight--d1521";
export var highlightSmall = "numbers-module--highlight-small--8737f";
export var image = "numbers-module--image--41c17";
export var item = "numbers-module--item--293b2";
export var number = "numbers-module--number--f40e9";
export var symbol = "numbers-module--symbol--511d0";
export var text = "numbers-module--text--a37cb";
export var textHighlight = "numbers-module--text-highlight--a59b5";
export var wrapper = "numbers-module--wrapper--e6f54";