import novidades from '@/assets/images/novidades-simples-dental-2023.png';
import novidadesSmall from '@/assets/images/novidades-simples-dental-2023-small.png';
import { routes } from '@/constants/routes';
import { homeButtonNames } from '@/constants/analytics';
import { Funcionalidade } from '../Funcionalidade/constants';

export interface NovidadesItem {
  id: number;
  name: string;
  desc: string;
}

export const novidadesItems: NovidadesItem[] = [
  {
    name: 'Alexa no Simples Dental',
    desc: 'sua agenda na ponta da língua para você poder dedicar mais atenção e cuidado aos pacientes.',
    id: 1,
  },
  {
    name: 'Assinatura Eletrônica',
    desc: 'proteja sua carreira com a validade jurídica de contratos e acabe de vez com o papel na clínica.',
    id: 2,
  },
  {
    name: 'Modelos de contratos',
    desc: 'use o modelo padrão totalmente integrado ao Simples Dental e também armazene diferentes modelos que desejar (ilimitados).',
    id: 3,
  },
  {
    name: 'Criador de site',
    desc: 'crie seu site para captar novos pacientes sem ficar dependendo de indicação ou planos de saúde.',
    id: 4,
  },
];

export const funcionalidade: Funcionalidade = {
  title: 'Controle sua agenda por voz com a Alexa',
  subtitle: 'Exclusivo',
  desc: 'Organize seu tempo com uma agenda que fala e conheça novos recursos no Simples Dental:',
  image: novidades,
  imageSmall: novidadesSmall,
  imageWidth: 722,
  imageHeight: 474,
  alt: 'Controle sua agenda por voz com a Alexa',
  imageTitle:
    'Imagem ilustrativa para o texto de Controle sua agenda por voz com a Alexa da Simples Dental',
  link: routes.lancamentos,
  left: true,
  gaMetadata: {
    saibaMaisButtonName: homeButtonNames.saibaMaisNovidades,
  },
  buttonText: 'Conheça aqui',
  id: 0,
  imageLoading: 'eager',
};
