// extracted by mini-css-extract-plugin
export var after = "hero-module--after--e1f4c";
export var bahamaBlue = "hero-module--bahama-blue--babb9";
export var blackNovember = "hero-module--black-november--bf1e5";
export var blue = "hero-module--blue--6ee13";
export var buttonBlue = "hero-module--button-blue--5bb4b";
export var container = "hero-module--container--bf50b";
export var desc = "hero-module--desc--7e296";
export var descIcon = "hero-module--desc-icon--a3df6";
export var descText = "hero-module--desc-text--ea6cc";
export var descTextGratis = "hero-module--desc-text-gratis--1e558";
export var grey = "hero-module--grey--a0806";
export var heroActions = "hero-module--hero-actions--f21cd";
export var heroActionsAlt = "hero-module--hero-actions-alt--867fb";
export var heroButtons = "hero-module--hero-buttons--7a2f3";
export var heroText = "hero-module--hero-text--4f383";
export var imgBlackNovember = "hero-module--img-black-november--f2a82";
export var imgBlackNovemberMobile = "hero-module--img-black-november-mobile--1a6f9";
export var imgBlackNovemberMobileAlt = "hero-module--img-black-november-mobile-alt--27ff7";
export var imgDesktop = "hero-module--img-desktop--7dcc8";
export var imgDesktopDesconto = "hero-module--img-desktop-desconto--8f02b";
export var imgMobile = "hero-module--img-mobile--7411b";
export var imgMobileDesconto = "hero-module--img-mobile-desconto--5c0cf";
export var imgMobileLogo = "hero-module--img-mobile-logo--bff44";
export var scorpionGrassBlue = "hero-module--scorpion-grass-blue--d5141";
export var subtitle = "hero-module--subtitle--93e1e";
export var title = "hero-module--title--230c2";
export var typeWrite = "hero-module--type-write--22893";